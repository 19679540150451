import { Routes, Route } from 'react-router-dom';
import Layout from './components/Layout';
import RequireAuth from './components/RequireAuth';

//Screens
import Login from "./screens/Login";
import BarcodeGenerator from "./screens/BarcodeGenerator";
import Error from "./screens/Error";
import RedirectToFindMyDevice from "./screens/RedirectToFindMyDevice";

//Styles
import "./styles/App.scss";

function App() {
  return (
    <Routes>
      <Route path='/' element={<Layout />}>
        {/* Public access */}
        <Route path='/' element={<Login />} />

        {/* Protected access */}
        <Route element={<RequireAuth />}>
          <Route path='/BarcodeGenerator' element={<BarcodeGenerator />} />
        </Route>

	{/* Redirection */}
        <Route path='/FindMyDevice' element={<RedirectToFindMyDevice />} />
        <Route path='/monitoring.html' element={<RedirectToFindMyDevice />} />

        {/* Catch all */}
        <Route path='*' element={<Error />} />
      </Route>
    </Routes>
  );
}

export default App;
