import { useNavigate } from "react-router-dom"

//AntD
import { Button } from "antd";

//Local Components
import Header from "../components/layouts/Header";
import Footer from "../components/layouts/Footer";

const Error = () => {

  const navigate = useNavigate();

  const goToHome = async () => {
    navigate('/');
  }

  return (
    <div className="app-container">
      <Header />
      <div className="error-msg-container">
        <h1 className="error-type">404</h1>
        <h1 className="error-headline">Something's Wrong!</h1>
        <p className="error-description">
          The page you were looking for doesn’t exist
        </p>
        <Button type="default" size="large" onClick={goToHome}>
          Return to Homepage
        </Button>
      </div>
      <Footer />
    </div>
  );
};

export default Error;
