import WMTBarcodeGeneratorRGBLogo from "../../styles/resources/images/content/logos/WMT_Barcode_Generator_RGBLogo.svg";

const Header = () => {
  return (
    <header>
      <img
        src={WMTBarcodeGeneratorRGBLogo}
        className="app-logo"
        alt="wmt_barcode_generator_logo"
      />
    </header>
  );
};

export default Header;
