//React
import { useState,useEffect,useRef,useContext } from "react";
import { useNavigate } from "react-router-dom"

//Local Components
import Header from "../components/layouts/Header";
import Footer from "../components/layouts/Footer";
import { Select, Button } from "antd";
import AuthContext from "../context/AuthProvider";
import axios from "../api/axios";

//Placeholders /* Not Important, can be removed */
import resetBarcode from "../styles/resources/images/placeholders/Reset.svg";

const GET_DAT_URL = '/get-data';
const GENARETE_PDF_URL = '/generate-pdf';

const BarcodeGenerator = () => {

  const { auth, setAuth } = useContext(AuthContext);
  const navigate = useNavigate();
  const [option, setOption] = useState('');
  const [data, setData] = useState([]);
  const [opcoList, setOpcoList] = useState([]);
  const [selected, setSelected] = useState('');
  const [opcoName, setOpcoName] = useState('');
  const [ogId, setOgId] = useState('');
  const [profileID, setProfileID] = useState('')
  const errRef = useRef();
  const [errMsg, setErrMsg] = useState('');

  const [loadings, setLoadings] = useState([]);
  const enterLoading = (index) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;
      return newLoadings;
    });
    setTimeout(() => {
      setLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[index] = false;
        return newLoadings;
      });
    }, 3000);
  };


  const getData = async () => {
    try {
      const response = await axios.post(GET_DAT_URL,
          {
            'token': auth.accessToken, 'deviceUsage': option
          }
      );
      setData(response.data);
      const items = [];      
      response.data.map((item) => (
        items.push({"value": item.ProfileID, "label": item.OG_Name})
      ))
      setSelected('');
      setOpcoList(items);
    } catch (err) {
      navigate('/');
      if (!err.response) {
          console.log('No Server Response');
      } else if (err.response.status === 400) {
          console.log('Missing Username or Password');
      } else if (err.response.status === 401) {
          console.log('Invalid token');
      } else if (err.response.status === 500){
          console.log('Internal server error');
      } else {
        console.log('Login Failed');
      }
    } 
  };

  const handleOgIdChange = (event) => {
    setProfileID(event);
    setSelected(event)
    for (let item in data) { 
      if(data[item]['ProfileID'] === event){
        setOgId(data[item]['OGID']);
        setOpcoName(data[item]['OG_Name']);
        break;
      }    
    }
  };

  const handleGenerateClick = async () => {
    if(option !== '' && selected !== ''){
      enterLoading(0);

      try {
        const response = await axios.post(GENARETE_PDF_URL, {
          "token":auth.accessToken,"deviceUsage":option,"ogId":ogId,"profileID":profileID
        });
        const base64Data = response.data;
        const linkSource = `data:application/pdf;base64,${base64Data}`;
        const downloadLink = document.createElement("a");
        const fileName = opcoName+".pdf";

        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
        
      } catch (error) {
	navigate('/');
        console.log('Error:', error);
      }
    } else {
      setErrMsg('Fill all fields');      
    }   

  };

  const logout = async () => {
    // if used in more components, this should be in context 
    // axios to /logout endpoint 
    setAuth({});
    navigate('/');
  }

  useEffect(() => {
    if (option !== "") {
      getData();
    }
  }, [option])

  useEffect(() => {
    setErrMsg('');
  }, [option, selected])


  return (
    <div className="app-container">
      <Header />
      <section className="barcode-generator-container">
        <div className="barcode-generator-form-container">
          <p ref={errRef} style={{color: "red"}} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
          <h2>Fill all fields to generate barcode</h2>
          <form className="form">
            <div className="input-wrapper">
              <label>Select type</label>
              <Select
                size="large"
                onSelect={(value) => setOption(value)}
                options={[
                  {
                    value: "Transportation",
                    label: "Transportation",
                  },
                  {
                    value: "Warehouse",
                    label: "Warehouse",
                  },
                ]}
              />
            </div>
            <div className="input-wrapper">
              <label>Search/Select OpCO</label>
              <Select
                onChange={value => handleOgIdChange(value)}
                // onChange={value => setSelected(value)}
                value={selected}
                showSearch
                size="large"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  (option?.label ?? "").includes(input)
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
                options={opcoList}
              />
            </div>
            <div className="btn-wrapper-col">
              {/* Generate Barcode goes to 'loading' state on click for 3s. If possible add function stop loading when barcode is displayed */}
              <Button
                loading={loadings[0]}
                // onClick={() => enterLoading(0)}
                onClick={handleGenerateClick}
                type="primary"
                size="large">
                Download Barcode
              </Button>
              <Button type="link" size="large" onClick={logout}>
                Logout
              </Button>
            </div>
          </form>
        </div>
        <div className="barcode-generator">
          {/* Change message to 'Scan the Barcode to Register Device' when barcode is displayed */}
          <div className="barcode-msg">To reset the device scan the below barcode</div>
          <div className="barcode-container-wrapper">
            <div className="barcode-container">
              {/* Add barcode here */}
              {/* Remove Dummy Barcode */}
              <img src={resetBarcode} alt="dummy_barcode" width={"100%"} height={"100%"}/>
            </div>
            <div className="barcode-punch punch-1"></div>
            <div className="barcode-punch punch-2"></div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default BarcodeGenerator;
