import { useRef, useState, useEffect } from "react"
import useAuth from "../hooks/useAuth";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "../api/axios";

//AntD
import { Button, Input, Form } from "antd";

const LOGIN_URL = '/generate-token';

const Login = () => {

  const {setAuth} = useAuth();

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/BarcodeGenerator";

    const userRef = useRef();
    const errRef = useRef();

    const [user, setUser] = useState('');
    const [pwd, setPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');

    useEffect(() => {
      userRef.current.focus();
    }, [])

    useEffect(() => {
      setErrMsg('');
    }, [user, pwd])

    const handleSubmit = async (e) => {
      try {
          const response = await axios.post(LOGIN_URL,
              JSON.stringify({username : user, password: pwd}),
              {
                  headers: {'Content-Type': 'application/json'},
                  withCredentials: true
              }
          );
          const accessToken = response.data.token;
          setAuth({user, pwd, accessToken});
          setUser('');
          setPwd('');
          navigate(from, {replace: true});
      } catch (err) {
          if (!err.response) {
              setErrMsg('No Server Response');
          } else if (err.response.status === 400) {
              setErrMsg('Missing Username or Password');
          } else if (err.response.status === 401) {
              setErrMsg('Unauthorized');
          } else {
              setErrMsg('Login Failed');
          }
          errRef.current.focus();
      }        
    }

  return (
    <div className="login-grid-container">
      <div className="login-hero">
        <img className="app-logo" alt="sysco_eag_process_tools_logo" />
        <h1 className="login-hero-txt">
          Streamline
          <br />
          <span className="login-hero-highlight-txt">Device Registration</span>
          <br />
          Effortlessly
        </h1>
      </div>
      <div className="login-content-container">
        <p ref={errRef} style={{color: "red" , paddingLeft : "2rem", margin: "auto", maxWidth: "36rem"}} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
        
        <div className="login-content">
          <div>
            <h1 className="login-heading">Login</h1>
            <p className="login-description">
              Sign in with your Credentials
            </p>
          </div>          
          
          

          <Form onFinish={handleSubmit} className="form">
            <div className="input-wrapper">
              <label htmlFor="username">Username</label>
              <Input 
                  type="text"
                  id="username"
                  ref={userRef}
                  autoComplete="off"
                  onChange={(e) => setUser(e.target.value)}
                  value={user}
                  required
              />
              <label htmlFor="password">Password</label>
              <Input.Password  
                  type="password"
                  id="password"
                  onChange={(e) => setPwd(e.target.value)}
                  value={pwd}
                  required
              />
            </div>
            <div className="btn-wrapper-col">
              <Button type="primary" size="large" htmlType="submit">Login</Button>
            </div>
          </Form>

          {/* <form className="form">
            <div className="input-wrapper">
              <label htmlFor="username">Username</label>
              <Input 
                name="username" 
                placeholder="eg: jdoe1802"
                type="text"
                id="username"
                ref={userRef}
                autoComplete="off"
                onChange={(e) => setUser(e.target.value)}
                value={user}
                required
              />
              {/* Pass suitable classes for validation
                1) eg: 'Incorrect Password' - error
                2) eg: 'Valid Username' - valid
                3) eg: 'Invalid Username' - warning
                4) eg: 'Password must be at least 8 characters' - hint
                <p ref={errRef} className="input-validation-msg error">{errMsg}</p>
              }
              <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>
              <p></p>
            </div>
            <div className="input-wrapper">
              <label htmlFor="password">Password</label>
              <Input.Password 
                name="password"
                type="password"
                id="password"
                onChange={(e) => setPwd(e.target.value)}
                value={pwd}
                required 
              />
            </div>
            <div className="btn-wrapper-col">
              <Button type="primary" size="large" onSubmit={handleSubmit}>
                Login
              </Button>
            </div>
            </form>*/}
        </div>
      </div>
    </div>
  );
};

export default Login;
